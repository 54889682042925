<template>
  <button class="filter-item" :class="{ selected: !isEmpty }" v-ripple>
    <v-icon v-if="isEmpty" size="20">add_circle_outline</v-icon>
    <v-icon v-else @click.stop="clear" size="20">cancel</v-icon>
    <span class="mx-1">{{ filter.name }}</span>
    <template v-if="!isEmpty">
      <v-divider vertical />
      <display-filter-value class="mx-1 primary--text" :filter="filter" />
    </template>

    <v-menu
      v-model="menu"
      offset-y
      :close-on-content-click="false"
      activator="parent"
    >
      <v-card width="330">
        <div class="highlight pa-2 caption">Filter by {{ filter.name }}</div>
        <v-divider />
        <div class="pa-2">
          <component :is="component" :filter="filter" />
        </div>
      </v-card>
    </v-menu>
  </button>
</template>

<script lang="ts">
import type { FilterInput } from "./types";
import DateFilterInput from "./DateFilterInput.vue";
import FloatFilterInput from "./FloatFilterInput.vue";
import SelectFilterInput from "./SelectFilterInput.vue";
import CheckboxFilterInput from "./CheckboxFilterInput.vue";
import DisplayFilterValue from "./DisplayFilterValue.vue";
import { useFilterInput } from "./store";

export default defineComponent({
  props: {
    filter: { type: Object as PropType<FilterInput>, required: true },
  },
  components: { DisplayFilterValue },
  setup(props) {
    const { isEmpty, clear } = useFilterInput(props.filter);
    const menu = ref(false);

    const apply$1 = () => {
      menu.value = false;
    };

    return {
      isEmpty,
      clear,
      menu,
      apply: apply$1,
    };
  },
  computed: {
    component() {
      return match(this.filter.type, {
        date: DateFilterInput,
        float: FloatFilterInput,
        select: SelectFilterInput,
        checkbox: CheckboxFilterInput,
        default: "div",
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.filter-item {
  border-color: #c0c8d2;
  border-style: dashed;
  border-width: 1px;
  // color: #687385;
  border-radius: 20px;
  height: 24px;
  line-height: 24px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  padding: 2px;
  font-size: 12px;
  cursor: pointer;
  &.selected {
    border-style: solid;
  }
}
</style>
