<template>
  <div class="data-table-mobile-content flex flex-col gap-1 py-2">
    <div v-for="item in items" :key="item.itemKey">
      <Column v-bind="item" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Column from "./Column";
import type { DataTableColumn } from "./types";
import type { RouteLocationRaw } from "vue-router";

export default defineComponent({
  props: {
    item: { type: Object as PropType<any>, required: true },
    columns: { type: Array as PropType<DataTableColumn[]>, required: true },
    itemRoute: { type: Object as PropType<RouteLocationRaw> },
    showMobileTable: { type: Boolean, default: false },
  },
  components: { Column },
  computed: {
    items() {
      return this.columns
        .filter((column) => !column.display || column.display == "mobile")
        .map((column) => ({
          title: column.title,
          item: this.item,
          itemKey: column.key || column.value,
          component: column.type || column.component,
          itemRoute: this.itemRoute,
          showMobileTable: this.showMobileTable,
        }));
    },
  },
});
</script>
