<template>
  <ResourceAvatar class="data-table-avatar" v-bind="p" />
</template>

<script lang="ts">
import { get } from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    item: { type: Object as PropType<any>, required: true },
    imageKey: { type: String, default: "image" },
    altTextKey: { type: String },
    aspectRatio: { type: [String, Number] },
    height: { type: [String, Number] },
    width: { type: [String, Number] },
  },
  computed: {
    p() {
      return {
        image: get(this.item, this.imageKey),
        aspectRatio: this.aspectRatio,
        height: this.height,
        width: this.width,
        alt: this.altTextKey ? get(this.item, this.altTextKey) : undefined,
      };
    },
  },
});
</script>
