import type { Component } from "vue";
import type {
  DataTable,
  DataTableWrappedComponent,
} from "../private/data-table/types";
import { isFunction, isObject, isString } from "lodash";
import {
  FilterOperator,
  type SavedFilterValue,
  type SavedFilterValues,
} from "../private/data-table/Filters/types";

export const defineTable = <I, T extends DataTable<I>>(table: T): T => {
  table.columns.map((column) => {
    if (column.component && isObject(column.component)) {
      column.component = markRaw(column.component);
    }
  });
  return table;
};

export const dataTableComponent = (
  component: string | Component,
  o?: Omit<DataTableWrappedComponent, "component">
): DataTableWrappedComponent => {
  if (isObject(component)) {
    component = markRaw(component);
  }
  return {
    props: {},
    ...o,
    component,
  };
};

export const tableFilter = (
  filters:
    | Record<string, string | boolean | number | SavedFilterValue>
    | { (item: unknown): boolean }
) => {
  if (isFunction(filters)) {
    return filters;
  }
  return Object.entries(filters).reduce((acc, [k, v]) => {
    acc[k] = isObject(v)
      ? v
      : {
          operator: FilterOperator.Equal,
          values: [v],
        };
    return acc;
  }, {} as SavedFilterValues);
};
