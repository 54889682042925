<template>
  <v-btn v-if="show" v-bind="p" max-height="25" variant="elevated">
    <v-icon v-if="configuring" size="20">close</v-icon>
    <template v-else>
      <v-icon v-if="searchable" size="20">search</v-icon>
      <v-icon v-if="filterable" size="20">filter_list</v-icon>
    </template>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    searchable: Boolean,
    filterable: Boolean,
    configuring: Boolean,
  },
  computed: {
    show() {
      return this.filterable || this.searchable;
    },
    p() {
      const p: any = {};

      if (!this.configuring && this.filterable && this.searchable) {
        p["maxWidth"] = 50;
      } else {
        p["size"] = 30;
      }

      return p;
    },
  },
});
</script>
