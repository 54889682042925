import type { PropType } from "vue";
import { VCard, VDataTable, VSkeletonLoader } from "vuetify/components";
import { first, isBoolean } from "lodash";
import type { AppAction } from "../services/actions/types";
import type {
  DataTableColumn,
  DataTableComponent,
  DataTableFilters,
  DataTableModel,
  DataTableView,
} from "../private/data-table/types";
import ActionsMini from "../private/actions/ActionsMini.vue";
import DataTableActions from "../private/data-table/DataTableActions.vue";
import DataTableViews from "../private/data-table/DataTableViews.vue";
import DataTableAvatar from "../private/data-table/DataTableAvatar.vue";
import DataTableActionFeedback from "../private/data-table/DataTableActionFeedback.vue";
import { defaultView } from "../private/data-table/utils";
import type { SavedFilterValues } from "../private/data-table/Filters/types";
import Column from "../private/data-table/Column";
import DataTableMobileContent from "../private/data-table/DataTableMobileContent.vue";
import DataTableNoData from "../private/data-table/DataTableNoData.vue";
import {
  useFilterStore,
  useFilter,
  provideStore,
} from "../private/data-table/Filters/store";
import "../private/data-table/style.scss";
import { useDisplay } from "vuetify";
import type { RouteLocationRaw } from "vue-router";

export default defineComponent({
  name: "DataTable",
  props: {
    id: { type: String },
    modelValue: { type: Object as PropType<SavedFilterValues> },
    loading: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    configurable: { type: Boolean, default: false },
    applyFilters: { type: Boolean, default: false },
    filters: { type: Object as PropType<DataTableFilters> },
    views: { type: Array as PropType<DataTableView[]> },
    mobile: { type: Boolean, default: false },
    avatar: { type: [String, Boolean] }, // defaults to "image"
    actionsNamespace: { type: String },
    broadcastActions: { type: Boolean, default: false },
    columns: { type: Array as PropType<DataTableColumn[]>, required: true },
    actions: { type: Array as PropType<AppAction[]> },
    emptyActions: { type: Array as PropType<AppAction[]> },
    itemActions: { type: Array as PropType<AppAction[]> },
    itemCursorPointer: { type: Boolean, default: false },
    searchPlaceholder: { type: String, default: "Search..." },
    disablePagination: { type: Boolean, default: false },
    itemRoute: {
      type: Function as PropType<{ (item: any): RouteLocationRaw }>,
    },
    maxContentHeight: { type: [String, Number] },

    emptyIcon: { type: String },
    emptyTitle: { type: String },
    emptyDescription: { type: String },

    // Table component fields
    // customFilter,
    density: {
      type: String as PropType<"default" | "comfortable" | "compact">,
    },
    fixedHeader: { type: Boolean, default: false },
    showSelect: { type: Boolean, default: false },
    itemSelectable: String,
    itemValue: { type: String, default: "id" },
    items: { type: Array as PropType<any[]>, required: true },
    itemsPerPage: { type: [Number, String] },
    itemsPerPageOptions: { type: Array },
  },
  setup(props, ctx) {
    const tableId = props.id || uuid();
    const store = useFilterStore({
      id: tableId,
      columns: props.columns,
      items: props.items,
      filters: props.filters,
    });
    const { mobile } = useDisplay();

    provideStore(store);
    provide("tableId", tableId);

    const selection = ref<any[]>();
    const columns = ref<string[]>();
    const search = ref<string>();
    const activeView = ref<string>();
    const appliedFilters = ref<SavedFilterValues>();

    const $views = computed(() => props.views || []);

    const showMobileTable = computed(() => props.mobile && mobile.value);

    const view = computed(() => {
      const v = activeView.value
        ? $views.value.find((x) => x.id == activeView.value)
        : first(props.views);
      return v || defaultView;
    });

    const state = computed(() => ({
      views: $views.value,
      filters: view.value.filters || appliedFilters.value,
      columns: view.value.columns || columns.value,
      actions: view.value.actions || props.actions || [],
      itemActions: view.value.itemActions || props.itemActions || [],
      emptyActions: view.value.emptyActions || props.emptyActions || [],
    }));

    const tableModel = computed<DataTableModel>(() => ({
      selection: selection.value,
      search: search.value,
      filters: appliedFilters.value,
      columns: columns.value,
    }));

    const { filter } = useFilter(store, view);

    const computedItems = computed(() => {
      if (props.applyFilters) {
        return filter(props.items);
      }
      return props.items;
    });

    const showColumn = (column: DataTableColumn) => {
      if (column.display) {
        if (
          (mobile.value && column.display !== "mobile") ||
          (!mobile.value && column.display !== "desktop")
        ) {
          return false;
        }
      }
      if (view.value.columns && column.key) {
        return view.value.columns.includes(column.key);
      }
      return true;
    };

    watchEffect(() => {
      ctx.emit("update:modelValue", tableModel.value);
    });

    const handleAction = (action: AppAction, item?: any) => {
      if (!action.items && action.key) {
        ctx.emit("action:" + action.key, item);
      }
    };

    const handleClickedRow = (item: any) => {
      ctx.emit("click:row", item);
      if (props.actionsNamespace) {
        const { dispatch } = useNamespacedEvent(props.actionsNamespace);
        dispatch("click:row", item);
      }
    };

    return {
      selection,
      state,
      search,
      activeView,
      view,
      appliedFilters,
      computedItems,
      tableModel,
      showColumn,
      handleAction,
      handleClickedRow,
      showMobileTable,
    };
  },
  methods: {
    shouldShowViews() {
      return (
        this.configurable ||
        this.searchable ||
        !!this.views?.length ||
        !!this.filters
      );
    },
  },
  render() {
    const itemRoute = (item: any) => {
      if (this.itemRoute) {
        return this.itemRoute(item);
      }
    };

    const cardProps = {
      class: [
        "data-table",
        {
          "data-table--mobile": this.showMobileTable,
          "data-table--empty": !this.computedItems.length,
        },
      ],
    };

    return h(VCard, cardProps, () => {
      const headers: any[] = [];
      const components: Record<string, DataTableComponent<any>> = {};
      const titles: Record<string, string | undefined> = {};
      const hasItemActions = !!this.state.itemActions.length;

      if (this.avatar) {
        headers.push({
          key: "__table-avatar",
          width: 40,
          sortable: false,
        });
      }

      if (this.showMobileTable) {
        headers.push({
          key: "__table-mobile-content",
          sortable: false,
        });
      } else {
        for (const { component, ...column } of this.columns) {
          if (this.showColumn(column)) {
            headers.push({ sortable: false, ...column });
            const cmp = column.type || component;
            if (column.key) {
              titles[column.key] = column.title;
            }
            if (column.key && cmp) {
              components[column.key] = cmp;
            }
          }
        }
      }

      if (hasItemActions) {
        headers.push({
          key: "__table-actions",
          align: "end",
          width: 30,
          sortable: false,
        });
      }

      const tableProps: any = {
        style: {
          "--v-table-header-height":
            this.density == "compact" ? "29px" : "40px",
          maxHeight: this.maxContentHeight
            ? convertToUnit(this.maxContentHeight)
            : "auto",
          overflowY: "auto",
        },
        // customFilter, expanded
        density: this.density as any,
        fixedHeader: this.fixedHeader,
        showSelect: this.showSelect && !this.showMobileTable,
        itemValue: this.itemValue,
        loading: this.loading,
        search: this.applyFilters ? this.search : undefined,

        itemSelectable: this.itemSelectable,
        itemsPerPage: this.itemsPerPage,
        itemsPerPageOptions: this.itemsPerPageOptions,
        items: this.computedItems,
        headers,

        modelValue: this.selection,
        "onUpdate:modelValue": (v: any[]) => {
          this.selection = v;
          return true;
        },
        "onClick:row": (_event: PointerEvent, { item }: any) => {
          this.handleClickedRow(item);
          const route = itemRoute(item);
          if (route) {
            this.$router.push(route);
          }
        },
        // "onContextmenu:row": (_event: PointerEvent, { item }: any) => {
        //   this.$emit("contextmenu:row", item);
        // },
      };

      const tableSlots: any = {
        loading: () => h(VSkeletonLoader, { type: "table-row@4" }),
        "no-data": () => {
          const props = {
            icon: this.emptyIcon,
            title: this.emptyTitle,
            description: this.emptyDescription,
            view: this.view,
          };

          return h(DataTableNoData, props);
        },
        "item.__table-mobile-content": ({ item }: any) => {
          const props = {
            item,
            columns: this.columns.filter(this.showColumn),
            itemRoute: itemRoute(item),
            showMobileTable: this.showMobileTable,
          };
          return h(DataTableMobileContent, props);
        },
        "item.__table-avatar": ({ item }: any) => {
          const imageKey = isBoolean(this.avatar) ? "image" : this.avatar;
          return h(DataTableAvatar, { imageKey, item });
        },
        "item.__table-actions": ({ item }: any) =>
          h(ActionsMini, {
            class: "border",
            actions: this.state.itemActions,
            payload: item,
            size: 25,
            variant: "elevated",
            namespace: this.actionsNamespace,
            broadcast: this.broadcastActions,
            onAction: (action: AppAction) => {
              this.handleAction(action, item);
            },
          }),
      };

      Object.entries(components).forEach(([key, cmp]) => {
        tableSlots[`item.${key}`] = (slot: any) => {
          return h(Column, {
            ...slot,
            itemKey: key,
            component: cmp,
            itemRoute: itemRoute(slot.item),
            title: titles[key],
            showMobileTable: this.showMobileTable,
          });
        };
      });

      const actionsProps = {
        selection: this.selection,
        actions: this.state.actions,
        namespace: this.actionsNamespace,
        onAction: (action: AppAction) =>
          this.handleAction(
            action,
            this.selection ? [...this.selection] : undefined
          ),
      };

      const slots = {
        ...this.$slots,
      };

      // here we want to allow slot names in format `item:<item-key>`
      const k1 = "item:";
      const k2 = "item.";
      for (const key of Object.keys(slots)) {
        if (key.startsWith(k1)) {
          const k = k2 + key.replace(k1, "");
          slots[k] = slots[key];
          delete slots[key];
        }
      }

      const actions = h(DataTableActions, actionsProps);
      const table = h(VDataTable, tableProps, {
        ...tableSlots,
        ...slots,
      });
      const tableNode = h("div", { class: "relative" }, [actions, table]);
      const actionFeedback = h(DataTableActionFeedback, {
        tableModel: this.tableModel,
      });

      const items = [tableNode, actionFeedback];

      if (this.shouldShowViews()) {
        const viewsOptions: any = {
          configurable: this.configurable,
          searchable: this.searchable,
          inputFilters: this.filters,
          inputViews: this.views,
          views: this.state.views,
          modelValue: this.activeView,
          search: this.search,
          filters: this.appliedFilters,
          items: this.items,
          searchPlaceholder: this.searchPlaceholder,
          "onUpdate:modelValue": (v: any) => {
            this.activeView = v;
          },
          "onUpdate:search": (v: any) => {
            this.search = v;
          },
          "onUpdate:filters": (v: any) => {
            this.appliedFilters = v;
          },
        };
        const views = h(DataTableViews, viewsOptions, {
          before: this.$slots["view:before"],
        });
        items.unshift(views);
      }

      if (this.$slots.before) {
        const before: any = this.$slots.before();
        items.unshift(before);
      }

      return items;
    });
  },
});
