<template>
  <v-select
    v-model="value0"
    :items="items"
    rounded="lg"
    density="compact"
    variant="outlined"
    hide-details
  />
</template>
<script lang="ts">
import { type SelectFilter } from "./types";
import { useFilterInput } from "./store";

export default defineComponent({
  props: {
    filter: { type: Object as PropType<SelectFilter>, required: true },
  },
  setup(props) {
    const { useValue } = useFilterInput(props.filter as any);
    const value0 = useValue(0);
    const items = computed(() => props.filter.items || []);

    return {
      value0,
      items,
    };
  },
});
</script>
