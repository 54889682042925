<template>
  <div class="view-controls">
    <div
      class="px-4 py-2 flex-align relative"
      :class="{ 'h-40px': searchOnly || filtersOnly }"
    >
      <div
        v-if="configuring || searchOnly || filtersOnly || $slots.before"
        class="flex-grow absolute inset-0"
      >
        <v-text-field
          v-if="configuring && searchable"
          v-model="searchTerm"
          class="h-full"
          :placeholder="searchPlaceholder"
          density="compact"
          autofocus
          tile
          hide-details
        />
        <div v-else-if="inputFilters" class="px-4 h-full flex-align">
          <Filters v-model="selectedFilters" />
        </div>
        <div v-if="$slots.before" class="px-4 h-full flex-align">
          <slot name="before"></slot>
        </div>
      </div>
      <div
        v-if="items"
        class="flex-grow transition-opacity relative min-w-0"
        :class="{ 'opacity-0 pointer-events-none': configuring }"
      >
        <div class="max-w-full overflow-x-auto mr-1">
          <div class="flex-align gap-2 max-w-full">
            <v-btn
              v-for="(item, i) in items"
              :key="i"
              variant="text"
              :active="item.active"
              @click="select(item)"
            >
              <span class="font-bold opacity-80">{{ item.title }}</span>
              <v-icon
                v-if="item.custom && item.active"
                size="20"
                class="ml-1"
                icon="expand_more"
              />
            </v-btn>
            <v-btn
              v-if="configurable"
              @click="dispatch('add-view')"
              size="25"
              icon
            >
              <v-icon size="20">add</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div
        class="h-full flex justify-end items-center gap-2"
        :class="{ 'w-full': searchOnly }"
      >
        <template
          v-if="configurable || (searchable && (views || inputFilters))"
        >
          <v-btn
            v-if="configurable && configuring"
            @click="dispatch('save-view')"
            max-height="25"
            variant="elevated"
          >
            Save
          </v-btn>
          <DataTableViewBtn
            @click="toggleSearch"
            :searchable="searchable"
            :filterable="filterable"
            :configuring="configuring"
          />
        </template>
      </div>
    </div>
    <v-expand-transition v-if="searchable && inputFilters">
      <Filters v-if="configuring" v-model="selectedFilters" class="px-4 py-2" />
    </v-expand-transition>
    <v-divider />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { DataTableFilters, DataTableView } from "./types";
import DataTableViewBtn from "./DataTableViewBtn.vue";
import Filters from "./Filters";
import { defaultView, useTableAction } from "./utils";
import type { FilterInput } from "./Filters/types";

type DataTableViewItem = DataTableView & {
  active: boolean;
  custom?: boolean;
};

export default defineComponent({
  props: {
    modelValue: { type: String },
    search: { type: String },
    filters: { type: Array as PropType<FilterInput[]> },
    searchable: { type: Boolean, default: false },
    configurable: { type: Boolean, default: false },
    inputFilters: { type: Object as PropType<DataTableFilters> },
    inputViews: { type: Array as PropType<DataTableView[]> },
    views: { type: Array as PropType<DataTableView[]>, required: true },
    searchPlaceholder: { type: String },
  },
  components: { DataTableViewBtn, Filters },
  setup() {
    const { dispatch, handle } = useTableAction();
    const configuring = ref(false);

    handle("exit-configure", () => {
      configuring.value = false;
    });

    return {
      configuring,
      dispatch,
    };
  },
  methods: {
    toggleSearch() {
      this.configuring = !this.configuring;
      if (!this.configuring && this.searchOnly && this.searchTerm) {
        this.searchTerm = undefined;
      }
    },
    select(view: DataTableView) {
      this.$emit("update:modelValue", view.id);
    },
  },
  computed: {
    filterable() {
      return !!this.inputFilters;
    },
    searchTerm: {
      set(v: string | undefined) {
        this.$emit("update:search", v);
      },
      get() {
        return this.search;
      },
    },
    selectedFilters: {
      set(v: any) {
        this.$emit("update:filters", v);
      },
      get() {
        return this.filters;
      },
    },
    searchOnly() {
      return (
        this.searchable &&
        !this.configurable &&
        !this.inputViews?.length &&
        !this.inputFilters
      );
    },
    filtersOnly() {
      return (
        !!this.inputFilters &&
        !this.configurable &&
        !this.inputViews?.length &&
        !this.searchable
      );
    },
    items(): DataTableViewItem[] | undefined {
      if (this.views.length) {
        return this.views.map((view, i) => ({
          ...view,
          active: this.modelValue == view.id || (!this.modelValue && i == 0),
        }));
      }
      if (this.configurable) {
        return [{ ...defaultView, active: true }];
      }
    },
  },
});
</script>
