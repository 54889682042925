<template>
  <div
    class="data-table-actions absolute inset-0 h-40px px-4 transition-opacity opacity-0 pointer-events-none"
    :class="{ 'opacity-100 z-4': show }"
  >
    <div
      class="ml-10 highlight h-full flex-grow flex-align gap-2 pointer-events-auto"
    >
      <span class="caption">{{ count }} selected</span>
      <v-spacer />
      <AppActions
        v-if="actions"
        :actions="actions"
        :namespace="namespace"
        :payload="selection"
        @action="forwardAction"
        variant="elevated"
        height="25"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { AppAction } from "../../services/actions/types";

export default defineComponent({
  props: {
    actions: { type: Array as PropType<AppAction[]> },
    selection: { type: Array },
    namespace: { type: String },
  },
  computed: {
    count() {
      return this.selection?.length;
    },
    show() {
      return this.selection?.length && this.actions?.length;
    },
  },
  methods: {
    forwardAction(action: AppAction) {
      this.$emit("action", action);
    },
  },
});
</script>
