<template>
  <div>
    <!--  -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useTableAction } from "./utils";
import type { DataTableModel } from "./types";

export default defineComponent({
  props: {
    tableModel: { type: Object as PropType<DataTableModel> },
  },
  setup(props) {
    const { dispatch, handle } = useTableAction();
    const { wrapConfirmInput } = useConfirm();

    const exitConfig = () => dispatch("exit-configure");

    const inputs = defineFormInputs({
      name: {
        label: "Name",
        name: "Name",
        rules: "required",
        type: "text",
      },
    });

    const createOptions = { inputs, title: "Create new view" };
    const saveOptions = { inputs, title: "Save view as" };

    const addView = wrapConfirmInput((inputs: any) => {
      exitConfig();
    }, createOptions);

    const saveView = wrapConfirmInput((inputs: any) => {
      exitConfig();
    }, saveOptions);

    handle("add-view", () => {
      addView();
    });
    handle("save-view", () => {
      saveView();
    });
  },
});
</script>
