<template>
  <div class="filters">
    <filter-input
      v-for="filter in filterInputs"
      :key="filter.key"
      :filter="filter"
      class="mr-2"
    />
    <v-btn v-if="!isEmpty" @click="clear()" color="primary" variant="text">
      Clear filters
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FilterInputCmp from "./FilterInput.vue";
import type { FilterInput } from "./types";
import { injectStore } from "./store";

export default defineComponent({
  props: {
    modelValue: { type: Array as PropType<FilterInput[]> },
  },
  components: { FilterInput: FilterInputCmp },
  setup(props, ctx) {
    const store = injectStore();

    watch(store.state, (v) => {
      ctx.emit("update:modelValue", v);
    });

    return {
      clear: store.clear,
      isEmpty: store.isEmpty,
      filterInputs: store.filters,
    };
  },
});
</script>

<style lang="scss">
.filters {
  display: flex;
  align-items: center;
}
.arrow-input-container {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBmaWxsPSIjNjY2RUU4IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjggOS42NDZhNC4wMDkgNC4wMDkgMCAwIDEtMy44LTRDMCA0LjAyNC45NjYgMi42MjYgMi4zNTQgMkw0IDMuNjQ2YTIgMiAwIDEgMCAwIDRoNy41Mkw4LjI5MyA0LjM1M2wxLjQxNC0xLjQxNCA1LjcwNyA1LjcwNy01LjcwNyA1LjcwNy0xLjQxNC0xLjQxNCAzLjIyNy0zLjI5M0gzLjh6Ii8+PC9zdmc+)
    no-repeat;
  background-position: 4px center;
  background-size: 12px;
  padding-left: 28px;
}
</style>
