<template>
  <div>
    <v-select
      v-model="operator"
      :items="items"
      rounded="lg"
      density="compact"
      hide-details
      variant="outlined"
    />
    <div v-if="isLastN" class="arrow-input-container mt-2 flex-align">
      <v-text-field
        v-model="number"
        rounded="lg"
        class="mr-1"
        min="0"
        step="1"
        density="compact"
        variant="outlined"
        hide-details
        single-line
      />
      <v-select
        v-model="format"
        rounded="lg"
        class="ml-2"
        :items="formats"
        density="compact"
        variant="outlined"
        hide-details
        single-line
      />
    </div>

    <div v-else class="mt-2 flex-align">
      <v-text-field
        :model-value="startDate"
        class="start-date"
        rounded="lg"
        prepend-inner-icon="today"
        density="compact"
        variant="outlined"
        hide-details
        single-line
      />

      <v-menu
        v-model="menu0"
        activator=".start-date"
        location="bottom"
        transition="slide-y-transition"
        offset="15"
        :close-on-content-click="false"
      >
        <v-confirm-edit v-model="startDate" @save="save0">
          <template v-slot:default="{ model, actions }">
            <v-card width="auto">
              <v-date-picker v-model="model.value" />
              <template v-slot:actions>
                <v-spacer></v-spacer>
                <component :is="actions"></component>
              </template>
            </v-card>
          </template>
        </v-confirm-edit>
      </v-menu>
    </div>
  </div>
</template>
<script lang="ts">
import { FilterOperator, type DateFilter, type DateDaysFormat } from "./types";
import { useFilterInput } from "./store";

const formats: DateDaysFormat[] = ["days", "weeks"];

export default defineComponent({
  props: {
    filter: { type: Object as PropType<DateFilter>, required: true },
  },
  setup(props) {
    const { useValue, operator } = useFilterInput(props.filter as any);

    const startDate = useValue(0);
    const endDate = useValue(1);
    const number = useValue(2, 0);
    const format = useValue<DateDaysFormat>(3, "days");
    const isLastN = computed(() => operator.value == FilterOperator.LastN);
    const isBetween = computed(() => operator.value == FilterOperator.Between);

    return {
      startDate,
      endDate,
      operator,
      isLastN,
      isBetween,
      number,
      format,
    };
  },
  data: () => ({
    menu0: false,
    menu1: false,
    items: [
      { title: "is in the last", value: FilterOperator.LastN },
      { title: "is equal to", value: FilterOperator.Equal },
      { title: "is between", value: FilterOperator.Between },
      { title: "is after", value: FilterOperator.Greater },
      { title: "is on or after", value: FilterOperator.GreaterOrEqual },
      { title: "is before", value: FilterOperator.Lesser },
      { title: "is on or before", value: FilterOperator.LesserOrEqual },
    ],
    formats,
  }),
  methods: {
    save0() {
      this.menu0 = false;
    },
    save1() {
      this.menu1 = false;
    },
  },
});
</script>
