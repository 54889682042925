import {
  FilterOperator,
  type SavedFilterValue,
  type FilterInput,
  type SavedFilterValues,
} from "./types";
import {
  get,
  has,
  isEmpty,
  isFunction,
  isNil,
  isString,
  isUndefined,
} from "lodash";
import { checkFilter, isItemsType } from "./utils";
import type {
  DataTableColumn,
  DataTableFilters,
  DataTableView,
} from "../types";

export const useFilterStore = (table: {
  id: string;
  columns: DataTableColumn[];
  items?: any[];
  filters?: DataTableFilters;
}) => {
  const state = ref<SavedFilterValues>({});

  const upsertFilter = (key: string, data: Partial<SavedFilterValue>) => {
    state.value = {
      ...state.value,
      [key]: {
        ...get(state.value, key),
        ...data,
      },
    };
  };

  const hasFilterValue = (key: string) => {
    return has(state.value.filterValues, key);
  };

  const isEmptyFilter = (key: string) => {
    if (!state.value[key]?.values) {
      return true;
    }

    const values = state.value[key].values.filter(
      (v) => !(isNil(v) || (Array.isArray(v) && !v.length))
    );

    return !values.length;
  };

  const getColumnTitle = (name: string): string => {
    const header = table.columns.find((h) => (h.key || h.value) == name);
    return header?.title || name;
  };

  const empty = computed(
    () => !Object.keys(state.value).some((k) => !isEmptyFilter(k))
  );

  const filters = computed((): FilterInput[] => {
    return Object.entries(table.filters || {}).map(
      ([name, filter]): FilterInput => {
        const f = isString(filter) ? { type: filter } : filter;
        const input: FilterInput = {
          ...f,
          name: f.name || getColumnTitle(name),
          key: name,
        };

        if (isItemsType(input)) {
          if (!input.items && table.items) {
            // if enum but items not provided,
            // attempt to extract from table items' value
            let values = table.items
              .map((item) => get(item, name))
              .filter((v) => !isUndefined(v));
            values = [...new Set(values)];

            input.items = values.map((value) => ({
              title: enumTitle(value),
              value,
            }));
          } else if (input.items && !Array.isArray(input.items)) {
            input.items = Object.entries(input.items).map(([title, value]) => ({
              title,
              value,
            }));
          }
        }

        return input;
      }
    );
  });

  const clear = (key?: string) => {
    const keys = key ? [key] : Object.keys(Object.assign({}, state.value));

    for (const k of keys) {
      if (state.value[k]) {
        upsertFilter(k, {
          values: state.value[k].values.map((v) =>
            Array.isArray(v) ? [] : undefined
          ),
        });
      }
    }
  };

  return {
    id: table.id,
    state,
    filters,
    clear,
    upsertFilter,
    hasFilterValue,
    isEmptyFilter,
    isEmpty: empty,
  };
};

export type IStore = ReturnType<typeof useFilterStore>;

export const provideStore = (store: IStore) => provide("filterStore", store);
export const injectStore = (): IStore => inject("filterStore")!;

export const useFilter = (
  { filters, state }: IStore,
  view: Ref<DataTableView>
) => {
  const keys = (): string[] => {
    const k1 = Object.keys(view.value.filters || {});
    const k2 = filters.value.map((f) => f.key);
    return unique([...k1, ...k2]);
  };

  const checksFilter = <A extends any[]>(fn: {
    (item: any, ...a: A): boolean;
  }) => {
    return (item: any, ...a: A): boolean => {
      if (view.value.filters && isFunction(view.value.filters)) {
        return view.value.filters(item);
      }
      return fn(item, ...a);
    };
  };

  const s = (k: string) => {
    if (!view.value?.filters) {
      return state.value[k];
    }
    if (isFunction(view.value.filters)) {
      // this should not happen
      return {} as any;
    }
    if (view.value.filters[k]) {
      return view.value.filters[k];
    }
  };

  const check = checksFilter((item: any, key: string): boolean => {
    if (!s(key)) {
      return true;
    }

    const targetFilter = s(key);
    const target = get(item, key);

    return checkFilter(target, targetFilter);
  });

  const checkAll = checksFilter((item: any): boolean => {
    return keys().every((key) => check(item, key));
  });

  const checkAny = checksFilter((item: any): boolean => {
    return keys().some((key) => check(item, key));
  });

  const filter = <T>(items: T[]): T[] => {
    if (
      isEmpty(state.value) &&
      (!view.value?.filters ||
        (!isFunction(view.value?.filters) && isEmpty(view.value?.filters)))
    ) {
      return items;
    }
    return items.filter(checkAll);
  };

  return {
    filter,
    checkAll,
    checkAny,
  };
};

export const useFilterInput = (filter: FilterInput) => {
  const store = injectStore();

  const getFilter = () => {
    return store.state.value[filter.key];
  };

  const defu: SavedFilterValue = getFilter() || {
    type: filter.type,
    operator: FilterOperator.Equal,
    values: [],
  };

  if (!store.hasFilterValue(filter.key)) {
    store.upsertFilter(filter.key, defu);
  }

  const operator = computed({
    set(v: FilterOperator) {
      store.upsertFilter(filter.key, { operator: v });
    },
    get() {
      return getFilter()?.operator || FilterOperator.Equal;
    },
  });

  const isEmpty = computed(() => store.isEmptyFilter(filter.key));

  const clear = () => store.clear(filter.key);

  const useValue = <V>(index: number, defu?: V) => {
    const set = (value: V) => {
      const values = getFilter()?.values || [];
      values[index] = value;
      store.upsertFilter(filter.key, { values });
    };

    if (!isUndefined(defu)) {
      set(defu);
    }

    return computed({
      set,
      get(): V {
        return get(getFilter()?.values, index) as V;
      },
    });
  };

  return {
    isEmpty,
    operator,
    clear,
    useValue,
  };
};
