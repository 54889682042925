<template>
  <span>{{ text }}</span>
</template>

<script lang="ts">
import { FilterOperator, type FilterInput } from "./types";
import { useFilterInput } from "./store";
import {
  isCheckboxFilter,
  isSelectFilter,
  isFloatFilter,
  isDateFilter,
} from "./utils";

export default defineComponent({
  props: {
    filter: { type: Object as PropType<FilterInput>, required: true },
  },
  setup(props) {
    const { operator, useValue } = useFilterInput(props.filter);

    const value0 = useValue(0);
    const value1 = useValue(1);

    const formatDate = (v: any) => v;

    const text = computed(() => {
      if (isCheckboxFilter(props.filter)) {
        return Array.isArray(value0.value)
          ? value0.value.join(", ")
          : value0.value;
      }
      if (isSelectFilter(props.filter)) {
        return value0.value;
      }
      if (isFloatFilter(props.filter)) {
        if (operator.value === FilterOperator.Between) {
          return "$" + (value0.value || 0) + " to " + "$" + (value1.value || 0);
        }
        const prefix = match(operator.value, {
          [FilterOperator.Equal]: "Exactly",
          [FilterOperator.Greater]: "More than",
          [FilterOperator.Lesser]: "Less than",
        });
        return prefix + " $" + (value0.value || 0);
      }
      if (isDateFilter(props.filter)) {
        if (operator.value === FilterOperator.Between) {
          return formatDate(value0.value) + " - " + formatDate(value1.value);
        } else if (operator.value === FilterOperator.LastN) {
          const format = "days";
          return value0.value
            ? `Last ${value0.value} ${format}`
            : `Last ${format}`;
        }

        const prefix = match(operator.value, {
          [FilterOperator.Greater]: "After ",
          [FilterOperator.Lesser]: "Before ",
          [FilterOperator.GreaterOrEqual]: "Starting from ",
          [FilterOperator.LesserOrEqual]: "Ending on ",
          default: "",
        });

        return prefix + formatDate(value0.value);
      }

      return "-";
    });

    return {
      text,
    };
  },
});
</script>
