<template>
  <div>
    <v-select
      v-model="operator"
      :items="items"
      rounded="lg"
      density="compact"
      variant="outlined"
      hide-details
    />
    <div class="arrow-input-container mt-2 flex-align">
      <v-number-input
        v-model="value0"
        rounded="lg"
        placeholder="0"
        density="compact"
        variant="outlined"
        hide-details
      />
      <template v-if="isRange">
        <span class="mx-2">to</span>
        <v-number-input
          v-model="value1"
          rounded="lg"
          placeholder="0"
          density="compact"
          variant="outlined"
          hide-details
        />
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { FilterOperator, type FloatFilter } from "./types";
import { useFilterInput } from "./store";

export default defineComponent({
  props: {
    filter: { type: Object as PropType<FloatFilter>, required: true },
  },
  setup(props) {
    const { operator, useValue } = useFilterInput(props.filter as any);
    const value0 = useValue<number>(0);
    const value1 = useValue<number>(1);
    const isRange = computed(() => operator.value == FilterOperator.Between);

    return {
      operator,
      value0,
      value1,
      isRange,
    };
  },
  data: () => ({
    items: [
      { title: "is equal to", value: FilterOperator.Equal },
      { title: "is between", value: FilterOperator.Between },
      { title: "is greater than", value: FilterOperator.Greater },
      { title: "is less than", value: FilterOperator.Lesser },
    ],
  }),
});
</script>
