<template>
  <v-sheet max-height="200" style="overflow-y: auto">
    <v-checkbox
      v-model="selection"
      v-for="item in items"
      :key="item.value"
      :value="item.value"
      :label="item.title"
      density="compact"
      hide-details
    />
  </v-sheet>
</template>
<script lang="ts">
import type { CheckboxFilter } from "./types";
import { useFilterInput } from "./store";

export default defineComponent({
  props: {
    filter: { type: Object as PropType<CheckboxFilter>, required: true },
  },
  setup(props) {
    const { useValue } = useFilterInput(props.filter as any);

    const selection = useValue<string[]>(0, []);
    const items = computed(() => props.filter.items || []);

    return {
      selection,
      items,
    };
  },
});
</script>
